//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    handleAgain: function handleAgain() {
      this.$router.push('/entry/staff');
    } } };