var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "div",
            { staticStyle: { width: "65%", margin: "20px 10%" } },
            [
              _c(
                "el-steps",
                { attrs: { active: 3, "align-center": "" } },
                [
                  _c("el-step", {
                    attrs: { title: "步骤 1", description: "填写基本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 2", description: "填写样本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 3", description: "完成" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "c-box",
        [
          _c(
            "c-box",
            {
              staticStyle: {
                margin: "50px 0",
                display: "flex",
                "margin-left": "36%"
              },
              attrs: { flex: "row" }
            },
            [
              _c("img", {
                staticClass: "icon-finish",
                attrs: { src: require("../../../assets/imgs/finish.png") }
              })
            ]
          ),
          _c(
            "c-box",
            {
              staticStyle: {
                margin: "15px 0",
                display: "flex",
                "margin-left": "40%"
              },
              attrs: { flex: "row" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.handleAgain }
                },
                [_vm._v("再下一单")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }